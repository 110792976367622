import commaNumber from 'comma-number'
import styles from '@/styles/Notification.module.css'
import Image from 'next/image'
import dayjs from '@/data/dayjs'
import Link from 'next/link'

const Booking = ({ notificationDetail, remove, read }) => {
    const data = notificationDetail?.data ? notificationDetail.data : {}

    const aircraftType = data?.booking?.aircraftOptions?.length
        ? data?.booking?.aircraftOptions[0]?.aircraftClassName
        : ''
    const aircraftName = data?.booking?.aircraftOptions?.length
        ? data?.booking?.aircraftOptions[0]?.aircraftName
        : ''
    const aircraftPrice = data?.booking?.aircraftOptions?.[0]?.lineItems?.length
        ? commaNumber(
              data?.booking?.aircraftOptions?.[0]?.lineItems
                  ?.reduce(
                      (pre, current) => pre + parseFloat(current.amount || 0),
                      0
                  )
                  ?.toFixed(2)
          )
        : 0

    const notificationMessages = {
        'booking-price-quote-approved': {
            message: `<b>${data?.booking?.clientFirstName} ${data?.booking?.clientLastName}</b> requests a contract for ${aircraftName}, the ${aircraftType} for $${aircraftPrice}.`,
            link: `/workspace/${notificationDetail.bookingId}/contract`,
        },
        'booking-price-quote-recevied': {
            message: `Price quotation has been sent to client <b>${data?.booking?.clientFirstName} ${data?.booking?.clientLastName}</b> by broker.`,
            link: `/workspace/${notificationDetail.bookingId}/price-quote`,
        },
        'flight-quote-request-received': {
            message:
                data?.sender?.roleName === 'Client'
                    ? `New flight quote request received from <b>${data?.data?.clientFirstName} ${data?.data?.clientLastName}</b>.`
                    : `New flight quote created for <b>${data?.data?.clientFirstName} ${data?.data?.clientLastName}</b>.`,
            link: `/workspace/${notificationDetail.bookingId}/${
                data?.sender?.roleName === 'Client' ? 'flight' : 'price-quote'
            }`,
        },
        'booking-canceled': {
            message: `Booking id <b>${notificationDetail?.bookingId}</b> has been canceled.`,
            link: `/workspace/${notificationDetail.bookingId}/flight`,
        },
        'booking-archived': {
            message: `Booking id <b>${notificationDetail?.bookingId}</b> has been archived.`,
            link: `/workspace/${notificationDetail.bookingId}/flight`,
        },
        'booking-confirmed': {
            message: `The flight for ${data?.data?.clientLastName} to ${
                data?.destinationName || 'N/A'
            } has been confirmed.`,
            link: `/workspace/${notificationDetail.bookingId}/itinerary`,
        },
        'flight-additional-quotes-approved': {
            message: `Additional request quote has been approved for booking id <b>${notificationDetail?.bookingId}</b>.`,
            link: `/workspace/${notificationDetail.bookingId}/payment`,
        },
        'flight-additional-quotes-received': {
            message: `Additional request quote has been received for booking id <b>${notificationDetail?.bookingId}</b>.`,
            link: `/workspace/${notificationDetail.bookingId}/payment`,
        },
        'flight-additional-quotes-cancelled': {
            message: `Additional request quote has been cancelled for booking id <b>${notificationDetail?.bookingId}</b>.`,
            link: `/workspace/${notificationDetail.bookingId}/itinerary`,
        },
        'booking-cancellation-request-sent': {
            message: `Cancellation request has been received on booking id <b>${notificationDetail?.bookingId}</b>.`,
            link: `/workspace/${notificationDetail.bookingId}/flight`,
        },
        'payment-hold': {
            message: `Payment hold for booking id <b>${notificationDetail.bookingId}</b>.`,
            link: `/workspace/${notificationDetail.bookingId}/payment`,
        },
        'payment-charged': {
            message: `Payment charged for booking id <b>${notificationDetail.bookingId}</b>.`,
            link: `/workspace/${notificationDetail.bookingId}/payment`,
        },
        'payment-refunded': {
            message: `Payment refunded for booking id <b>${notificationDetail.bookingId}</b>.`,
            link: `/workspace/${notificationDetail.bookingId}/payment`,
        },
        'payment-added': {
            message: `Payment added for booking id <b>${notificationDetail.bookingId}</b>.`,
            link: `/workspace/${notificationDetail.bookingId}/payment`,
        },
        'payment-released': {
            message: `Payment released for booking id <b>${notificationDetail.bookingId}</b>.`,
            link: `/workspace/${notificationDetail.bookingId}/payment`,
        },
        'update-payment-method': {
            message: `The contract for <b>${
                data?.sender?.lastName
            }'s</b> flight to <b>${
                data?.lastDestinationCode || 'N/A'
            }</b> has been signed.`,
            link: `/workspace/${notificationDetail.bookingId}/payment`,
        },
        'update-booking': {
            message: `<b>${data?.data?.clientFirstName} ${data?.data?.clientLastName}</b> has updated details for modification in booking id <b>${notificationDetail.bookingId}</b>. Please modify the booking id <b>${notificationDetail.bookingId}</b> according to the updated details.`,
            link: `/workspace/${notificationDetail.bookingId}/flight`,
        },
        'update-client-notes': {
            message: `<b>${data?.sender?.firstName} ${data?.sender?.lastName}</b> has requested quote for additional requests for booking id <b>${notificationDetail.bookingId}</b>.`,
            link: `/workspace/${notificationDetail.bookingId}/itinerary`,
        },
    }

    let eta = 'TBD'
    if (
        data?.flight?.landingDate &&
        data?.flight?.landingTime &&
        data?.flight?.takeOffDate &&
        data?.flight?.takeOffTime
    ) {
        eta = dayjs
            .duration(
                dayjs(
                    dayjs(
                        ` ${data?.flight?.landingDate} ${data?.flight?.landingTime}`
                    )
                ).diff(
                    dayjs(
                        ` ${data?.flight?.takeOffDate} ${data?.flight?.takeOffTime}`
                    )
                )
            )
            .humanize()
    }

    notificationMessages['flight-status-alert'] = {
        message:
            data?.flight?.status === 'completed'
                ? `<a href="https://flightaware.com/live/flight/${data?.flight?.flightTrackingCode}"><b> ${data?.flight?.bookingFlight?.clientFirstName} ${data?.flight?.bookingFlight?.clientLastName}</b> just landed at ${data?.flight?.destinationCode} on ${data?.flight?.tailNumber}. Click here to track the aircraft.</a>`
                : ` <a href="https://flightaware.com/live/flight/${data?.flight?.flightTrackingCode}"><b>${data?.flight?.bookingFlight?.clientFirstName} ${data?.flight?.bookingFlight?.clientLastName}</b> just took off from ${data?.flight?.originCode} on ${data?.flight?.tailNumber}. ETA to ${data?.flight?.destinationCode} is ${eta}. Click here to track the aircraft.</a>`,
    }

    const message = notificationMessages[notificationDetail.feature]?.message
    const link = notificationMessages[notificationDetail.feature]?.link

    return (
        <li
            key={notificationDetail?.id}
            className={`${styles.item} ${
                notificationDetail?.isRead ? '' : ' bg-body-bg'
            }`}
            onClick={() => read(notificationDetail?.id)}
        >
            <div className={styles.notificationList}>
                <span className={styles.image}>
                    <Image
                        src={`/icons/${notificationDetail?.feature}.svg`}
                        alt={process.env.NEXT_PUBLIC_APP_NAME}
                        layout="fixed"
                        width={24}
                        height={24}
                    />
                </span>
                <span
                    className={`${
                        notificationDetail?.isRead ? '' : 'font-acumin-bold'
                    } ${styles.description}`}
                >
                    <span>
                        <p
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                                __html: message,
                            }}
                        ></p>
                    </span>
                    <span className={styles.time}>
                        {dayjs(notificationDetail?.createdAt).fromNow()}
                    </span>
                    <div className={styles.btnClose}>
                        <span className="flex items-center justify-center p-1">
                            <i
                                onClick={() => remove(notificationDetail?.id)}
                                className="icon-close text-xs text-common-black"
                            ></i>
                        </span>
                    </div>
                </span>

                {!notificationDetail?.isRead && (
                    <div className="absolute right-3 top-[9px]">
                        <div className="h-[6px] w-[6px] rounded-full bg-primary-500"></div>
                    </div>
                )}
            </div>
            {link && (
                <div>
                    <Link href={link}>
                        <a className="absolute top-0 left-0 h-full w-full "></a>
                    </Link>
                </div>
            )}
        </li>
    )
}

export default Booking
