/* eslint-disable max-lines */
const login = {
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    password: {
        required: 'Password field is required.',
        min: 'Password need to have at least 8 characters.',
        matches:
            'Password must contain at least one uppercase letter, one lowercase letter, one number.',
    },
}
const forgot = {
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
}
const resetPassword = {
    password: {
        required: 'New password field is required.',
        min: 'New password field must be between 8 to 50 characters.',
        max: 'New password field must be between 8 to 50 characters.',
        mismatch: 'New password and confirm password fields do not match.',
        same: 'New password cannot be same as current password.',
        matches: 'New password must contain atleast one integer value.',
    },
    confirmPassword: {
        min: 'Confirm password field must be between 8 to 50 characters.',
        max: 'Confirm password field must be between 8 to 50 characters.',
        required: 'Confirm password is required.',
        matches: 'Confirm password must contain atleast one integer value.',
    },
}
const changePassword = {
    oldPassword: {
        required: 'Old password is required.',
        min: 'Old password field must be between 8 to 50 characters.',
        max: 'Old password field must be between 8 to 50 characters.',
    },
    newPassword: {
        required: 'New password field is required.',
        min: 'New password field must be between 8 to 50 characters.',
        max: 'New password field must be between 8 to 50 characters.',
        mismatch: 'New password and confirm password fields do not match.',
        same: 'New password cannot be same as current password.',
    },
    confirmPassword: {
        min: 'Confirm password field must be between 8 to 50 characters.',
        max: 'Confirm password field must be between 8 to 50 characters.',
        required: 'Confirm password is required.',
    },
}
const userAccounts = {
    firstName: {
        required: 'First name field is required.',
        min: 'First name must be at least 2 characters.',
        max: 'First name must be at most 100 characters.',
    },

    lastName: {
        required: 'Last name field is required.',
        min: 'Last name must be at least 2 characters.',
        max: 'Last name must be at most 100 characters.',
    },

    companyName: {
        required: 'Company name field is required.',
        min: 'Company name must be at least 2 characters.',
        max: 'Company name must be at most 100 characters.',
    },

    permission: {
        required: 'Permission field is required.',
    },

    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    mobileNumber: {
        required: 'Mobile number field is required.',
        matches: 'Please enter a valid mobile number e.g +1 (925) 292-6668',
    },
    workMobileNumber: {
        required: 'Work phone number field is required.',
        matches: 'Please enter a valid work phone number e.g +1 (925) 292-6668',
    },
    streetAddress1: {
        min: 'Street address 1 needs to have at least 2 characters.',
        max: 'Street address 1 should not be more than 255 characters.',
    },
    streetAddress2: {
        min: 'Street address 2 needs to have at least 2 characters.',
        max: 'Street address 2 should not be more than 255 characters.',
    },
    city: {
        min: 'City needs to have at least 2 characters.',
        max: 'City should not be more than 100 characters.',
    },
    state: {
        min: 'State needs to have at least 2 characters.',
        max: 'State should not be more than 100 characters.',
    },
    zipCode: {
        min: 'Zip code needs to have at least 2 characters.',
        max: 'Zip code should not be more than 10 characters.',
    },
    roleName: {
        required: 'Account type field is required.',
        uuid: 'Please enter a valid Account type.',
    },
    referred: {
        required: 'Referred field is required.',
    },
    brokerId: {
        required: 'Broker field is required.',
    },
    newPassword: {
        required: 'New password field is required.',
        min: 'New password field must be between 8 to 50 characters.',
        max: 'New password field must be between 8 to 50 characters.',
        mismatch: 'New password and confirm password fields do not match.',
        same: 'New password cannot be same as current password.',
    },
    confirmPassword: {
        min: 'Confirm password field must be between 8 to 50 characters.',
        max: 'Confirm password field must be between 8 to 50 characters.',
        required: 'Confirm password is required.',
    },
    clientId: {
        required: 'Client field is required.',
    },
    subAccountId: {
        required: 'Sub Account field is required.',
    },
}
const cateringMenu = {
    title: {
        required: 'Title field is required.',
    },
    parentId: {
        required: 'Menu category field is required.',
    },
    description: {
        required: 'Short description field is required.',
        min: 'Short description min value is 2.',
        max: 'Short description max value is 255.',
    },
}
const configurations = {
    taxPercentage: {
        required: 'FET tax percentage field is required.',
        min: 'FET tax percentage min value is 0.',
        max: 'FET tax percentage max value is 100.',
        matches:
            'FET tax field must be numeric and may contain only two decimal places.',
    },
    segmentFeeTax: {
        required: 'Segment fee tax field is required.',
        min: 'Segment fee tax min value is 0.',
        max: 'Segment fee tax max value is 10000.',
        matches:
            'Segment fee field must be numeric and may contain only two decimal places.',
    },
    processingFeePercentage: {
        required: 'Credit card processing fee field is required.',
        min: 'Credit card processing fee min value is 0.',
        max: 'Credit card processing fee max value is 100.',
        matches:
            'Credit card processing fee field must be numeric and may contain only two decimal places.',
    },
    contactEmail: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    clientRegisterEmail: {
        invalid: 'Please enter a valid client register email address.',
    },
    contactNumber: {
        required: 'Contact number field is required.',
    },
    coverPhotoURLs: {
        required: 'Cover photos field is required.',
    },
}
const newsUpdate = {
    title: {
        required: 'Title field is required.',
    },
    coverPhoto: {
        required: 'Cover photo field is required.',
    },
    excerpt: {
        required: 'Excerpt field is required.',
    },
    content: {
        required: 'Content field is required.',
    },
}
const content = {
    content: {
        required: 'Content field is required.',
    },
    title: {
        required: 'Title field is required.',
    },
}
const aircraftClass = {
    name: {
        required: 'Name field is required.',
    },
    photoURLs: {
        required: 'Aircraft class photos field is required.',
    },
    insuranceFee: {
        required: 'Aircraft class insurance fee field is required.',
        greaterThan: 'Aircraft class insurance fee should be greater than 0.',
    },
}
const aircrafts = {
    name: {
        required: 'Name field is required.',
    },
    aircraftClassId: {
        required: 'Aircraft class field is required.',
    },
    subtitle: {
        required: 'Sub heading field is required.',
    },
    passengerCapacity: {
        required: 'Passengers field is required.',
    },
    maxSpeed: {
        required: 'Max speed field is required.',
    },
    range: {
        required: 'Range field is required.',
    },
    dimensions: {
        required: 'Dimensions field is required.',
    },
    description: {
        required: 'Description is required.',
    },
    slideOrder: {
        required: 'Slide Sequence field is required.',
        min: 'Slide Sequence field must be greater than 1.',
    },
    tabOrder: {
        required: 'Tab Sequence is required.',
        min: 'Tab Sequence field must be greater than 1.',
    },
    photoURLs: {
        required: 'Aircraft photos field is required.',
        max: 'You can upload maximum 3 photos.',
    },
}
const announcements = {
    message: {
        required: 'Message is required',
        min: 'Message needs to have at least 2 characters.',
        max: 'Message should not be more than 160 characters.',
    },
    userIds: {
        required: 'Select at least on user.',
    },
}
const frequentFlier = {
    firstName: {
        required: 'First name field is required.',
    },

    lastName: {
        required: 'Last name field is required.',
    },
    dateOfBirth: {
        required: 'Date of birth field is required.',
    },
    weight: {
        required: 'Estimated weight field is required.',
        min: 'Estimated weight need to have at least 1 characters.',
        max: 'Estimated weight should not be more than 5 characters.',
    },
    passportNumber: {
        required: 'Passport number field is required.',
    },
    streetAddress1: {
        min: 'Street address 1 needs to have at least 2 characters.',
        max: 'Street address 1 should not be more than 255 characters.',
    },
    streetAddress2: {
        min: 'Street address 2 needs to have at least 2 characters.',
        max: 'Street address 2 should not be more than 255 characters.',
    },
    city: {
        min: 'City needs to have at least 2 characters.',
        max: 'City should not be more than 100 characters.',
    },
    state: {
        min: 'State needs to have at least 2 characters.',
        max: 'State should not be more than 100 characters.',
    },
    zipCode: {
        min: 'Zip code needs to have at least 2 characters.',
        max: 'Zip code should not be more than 10 characters.',
    },
    passportExpirationDate: {
        required: 'Passport expiration date field is required.',
    },
}
const paymentMethod = {
    cardHolderName: {
        required: 'Cardholder name field is required.',
        min: 'Cardholder name need to have at least 2 characters.',
        max: 'Cardholder name should not be more than 100 characters.',
    },

    cardNumber: {
        required: 'Card number field is required.',
        min: 'Card number need to have at least 13 characters.',
        max: 'Card number should not be more than 16 characters.',
        typeError: 'Card number should be number.',
    },

    expirationMonth: {
        required: 'Expire month field is required.',
        min: 'Expire month need to have at least 2 characters.',
        max: 'Expire month should not be more than 2 characters.',
    },

    expirationYear: {
        required: 'Expire year field is required.',
        min: 'Expire year need to have at least 2 characters.',
        max: 'Expire year should not be more than 2 characters.',
    },

    cvc: {
        required: 'CVC field is required.',
        min: 'CVC need to have at least 3 characters.',
        max: 'CVC should not be more than 4 characters.',
        positive: 'CVC should  be positive number.',
    },

    billingAddress: {
        required: 'Billing address field is required.',
        min: 'Billing address needs to have at least 2 characters.',
        max: 'Billing address should not be more than 250 characters.',
    },

    notes: {
        required: 'Nickname field is required.',
        min: 'Nickname need to have at least 2 characters.',
        max: 'Nickname should not be more than 250 characters.',
    },

    streetAddress1: {
        min: 'Street address #1 needs to have at least 2 characters.',
        max: 'Street address #1 should not be more than 100 characters.',
    },

    streetAddress2: {
        min: 'Street address #2 needs to have at least 2 characters.',
        max: 'Street address #2 should not be more than 100 characters.',
    },

    city: {
        min: 'City needs to have at least 2 characters.',
        max: 'City should not be more than 100 characters.',
    },

    state: {
        min: 'State needs to have at least 2 characters.',
        max: 'State should not be more than 100 characters.',
    },

    zipCode: {
        min: 'Zip code needs to have at least 2 characters.',
        max: 'Zip code should not be more than 20 characters.',
    },

    isAuthorized: {
        required: 'You must agree to terms and conditions.',
    },
}
const flightTracking = {
    flightTrackingCode: {
        required: 'Flight tracking field is required.',
    },

    tailNumber: {
        required: 'Tail number field is required.',
    },
    aircraftOperatorPrice: {
        required: 'Aircraft operator price field is required',
    },
}
const addPassenger = {
    frequentFlierId: {
        required: 'Passenger flier field is required.',
    },

    firstName: {
        required: 'First name field is required.',
        min: 'First name needs to have at least 2 characters.',
        max: 'First name should not be more than 100 characters.',
    },

    lastName: {
        required: 'Last name field is required.',
        min: 'Last name needs to have at least 2 characters.',
        max: 'Last name should not be more than 100 characters.',
    },
    // dateOfBirth: {
    //     required: 'Date of birth field is required.',
    // },
    weight: {
        // required: 'Weight field is required.',
        min: 'Weight needs to have at least 2 characters.',
        max: 'Weight should not be more than 10 characters.',
    },
    // passportNumber: {
    //     required: 'Passport number field is required.',
    // },
    documents: {
        required: 'Documents field is required.',
    },
    email: {
        invalid: 'Please enter a valid email address.',
    },
    mobileNumber: {
        matches: 'Please enter a valid mobile number e.g +1 (925) 292-6668',
    },
    address: {
        min: 'Address needs to have at least 2 characters.',
        max: 'Address should not be more than 255 characters.',
    },
    streetAddress1: {
        min: 'Street address #1 needs to have at least 2 characters.',
        max: 'Street address #1 should not be more than 100 characters.',
    },

    streetAddress2: {
        min: 'Street address #2 needs to have at least 2 characters.',
        max: 'Street address #2 should not be more than 100 characters.',
    },

    city: {
        min: 'City needs to have at least 2 characters.',
        max: 'City should not be more than 100 characters.',
    },

    state: {
        min: 'State needs to have at least 2 characters.',
        max: 'State should not be more than 100 characters.',
    },

    zipCode: {
        min: 'Zip code needs to have at least 2 characters.',
        max: 'Zip code should not be more than 20 characters.',
    },
    passportExpirationDate: {
        required: 'Passport expiration date field is required.',
    },
}
const aircraftOption = {
    aircraftId: {
        required: 'Aircraft field is required.',
    },
    aircraftOperator: {
        required: 'Aircraft operator field is required.',
    },
    aircraftOperatorPrice: {
        required: 'Aircraft price field is required.',
    },
}
const invoiceItem = {
    name: {
        required: 'Line item field is required.',
    },
    type: {
        required: 'Type field is required.',
    },
    amount: {
        required: 'Amount field is required.',
        min: 'Amount min value is 1.',
    },
    percentage: {
        required: 'Percentage field is required.',
        min: 'Percentage min value is 0.1.',
        max: 'Percentage max value is 100.',
    },
}
const priceQuote = {
    aircraftOptions: {
        min: 'At least 1 option is required.',
    },
    total: {
        required: 'Total field is required.',
        min: 'Minimum value is 1.',
    },
    lineItems: {
        min: 'At least 1 line item is required.',
        name: {
            required: 'Line item name field is required.',
        },
        type: {
            required: 'Line item type field is required.',
        },
        amount: {
            required: 'Line item price field is required.',
            min: 'Line item amount min value is 1.',
        },
        percentage: {
            required: 'Line item percentage field is required.',
            min: 'Line item percentage min value is 0.1.',
            max: 'Line item percentage max value is 100.',
        },
    },
}
const selectCreditCard = {
    paymentMethod: {
        required: 'Payment method is required.',
    },
    paymentMethodId: {
        required: 'Card id is required.',
    },
}
const refund = {
    amount: {
        required: 'Refund Amount field is required.',
        min: 'Refund amount min value is: 1',
        max: 'Refund amount max value is: ',
    },
}
const addPayment = {
    amountReceived: {
        required: 'Amount field is required.',
        min: 'Amount min value is 0.0001.',
    },
    transactionId: {
        required: 'Transaction id field is required.',
    },
    receivingDate: {
        required: 'Receiving date field is required.',
    },
    paymentMethodId: {
        required: 'Card field is required.',
    },
}
export const contract = {
    title: {
        required: 'Title field is required.',
        min: 'Title needs to have at least 2 characters.',
        max: 'Title should not be more than 100 characters.',
    },
    insuranceFee: {
        required: 'Insurance fee field is required.',
        greaterThan: 'Insurance fee should be greater than 0.',
    },
    insuranceId: {
        required: 'Insurance policy template field is required.',
    },
}
const subscribe = {
    users: {
        required: 'Please select atleast 1 email reciept.',
    },
    flightIds: {
        required: 'Please select atleast 1 flight to subscribe.',
    },
}

const airports = {
    name: {
        required: 'Name field is required.',
    },
    airportType: {
        required: 'Airport Type field is required.',
    },
    longitude: {
        required: 'Longitude field is required.',
    },
    latitude: {
        required: 'Latitude is required.',
    },
    code: {
        required: 'Code field is required.',
    },
    UTCOffset: {
        required: 'UTC Offset field is required.',
    },
    country: {
        required: 'Country field is required',
    },
    state: {
        required: 'State field is required',
    },
    city: {
        required: 'City field is required',
    },
    UTCDaylightOffset: {
        required: 'UTC Daylight Savings Offset field is required',
    },
    daylightStartDate: {},
    daylightEndDate: {},
    address: {},
}

export {
    login,
    forgot,
    resetPassword,
    changePassword,
    userAccounts,
    cateringMenu,
    configurations,
    newsUpdate,
    content,
    aircraftClass,
    announcements,
    frequentFlier,
    paymentMethod,
    aircrafts,
    flightTracking,
    addPassenger,
    aircraftOption,
    invoiceItem,
    priceQuote,
    selectCreditCard,
    refund,
    addPayment,
    subscribe,
    airports,
}
