import FlightCalendarCard from '@/components/flight/FlightCalendarCard'
import Link from 'next/link'

const Details = ({ data, close }) => {
    return (
        <div className="showFlightDetails relative ">
            <div className="scrollable mb-5 max-h-[calc(100vh-195px)] px-4 pb-[60px]">
                {data?.map((item, index) => (
                    <div
                        key={index}
                        className="mb-4 rounded-md bg-interface-100 p-2"
                    >
                        <FlightCalendarCard
                            data={item}
                            modifications="sidebarComponent"
                        />
                    </div>
                ))}
            </div>
            <div
                className="absolute bottom-0 right-0 w-full cursor-pointer bg-common-white pt-4 pb-3 text-primary-500 underline"
                onClick={close}
            >
                <div className="w-full border-t border-interface-200 px-4 py-3">
                    <Link href="/workspace/flight-calendar">
                        <a className="text-primary-500 underline">View all</a>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Details
