import { useRouter } from 'next/router'

import Preloader from '@/components/base/Preloader'

import { useSession } from '@/contexts/Session'

const Guest = ({ children }) => {
    const { push, asPath } = useRouter()
    const { isAuthenticated, nextURL } = useSession({
        required: false,
        onAuthenticated() {
            if (
                (nextURL.path && !nextURL.path.startsWith('/_next')
                    ? nextURL.path
                    : '/dashboard') !== asPath
            ) {
                push(
                    nextURL.path && !nextURL.path.startsWith('/_next')
                        ? nextURL.path
                        : '/dashboard'
                )
            }
        },
    })

    if (!isAuthenticated) {
        return children
    }

    return <Preloader></Preloader>
}

export default Guest
