import React, { useEffect, useRef } from 'react'

import styles from '@/styles/Alert.module.css'

const Alert = ({ variant, message, size, icon, iconStyle, noSpace }) => {
    const alertRef = useRef(null)
    useEffect(() => {
        alertRef.current.scrollIntoView({
            behavior: 'smooth',
        })
    }, [])

    return (
        <div
            ref={alertRef}
            className={`${styles.alert} ${styles[variant]} ${styles[size]} ${
                noSpace ? 'mb-0' : ''
            }`}
        >
            <span>
                {icon && <i className={`${`icon-` + icon}  ${iconStyle}`}></i>}
            </span>
            <span>{message}</span>
        </div>
    )
}

export default Alert
