import dayjs from 'dayjs'
import { useEffect } from 'react'
import Sidebar from '../components/base/Sidebar'

import { useSession } from '@/contexts/Session'

const Main = (props) => {
    const { removeAuthToken } = useSession()

    useEffect(() => {
        const updateLastActivity = (event) => {
            const lastActivityAt = localStorage.getItem('lastActivityAt')

            if (dayjs().diff(dayjs(lastActivityAt), 'minutes') > 180) {
                localStorage.removeItem('lastActivityAt')
                removeAuthToken('accessToken', {
                    path: '/',
                })
            } else {
                localStorage.setItem('lastActivityAt', dayjs().format())
            }
        }
        updateLastActivity()

        document.addEventListener('keypress', updateLastActivity)
        document.addEventListener('mousemove', updateLastActivity)

        return () => {
            document.removeEventListener('keypress', updateLastActivity)
            document.removeEventListener('mousemove', updateLastActivity)
        }
    }, [removeAuthToken])

    return (
        <main className="relative">
            <div className="flex">
                <div className="w-[260px]">
                    {/* Sidebar */}
                    <Sidebar />
                </div>
                <div className="right-panel relative">{props.children}</div>
            </div>
        </main>
    )
}

export default Main
