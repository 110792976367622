import Link from 'next/link'
import { useEffect } from 'react'

import Logo from '../components/Logo'

import { useSession } from '@/contexts/Session'

import styles from '../styles/Auth.module.css'

const Auth = (props) => {
    const { removeAuthToken } = useSession()

    useEffect(() => {
        localStorage.removeItem('lastActivityAt')
    }, [removeAuthToken])

    return (
        <main>
            <div className={styles.authMain}>
                <div className={styles.authLeft}>
                    <div className={styles.authContainer}>
                        <div className={styles.card}>
                            <div className={styles.cardBody}>
                                <div className={styles.logo}>
                                    <Logo width={80} height={76} link="/" />
                                </div>

                                <h1 className="auth-heading">
                                    {props.heading}
                                </h1>
                                {props.textDescription && (
                                    <p
                                        className={`mr-1 inline-block text-sm ${styles.textDescription}`}
                                    >
                                        {props.textDescription}
                                    </p>
                                )}
                                {props.linkDescription && (
                                    <Link
                                        href={props.link}
                                        className={styles.linkDescription}
                                    >
                                        <a>{props.linkDescription}</a>
                                    </Link>
                                )}
                                {props.children}
                            </div>
                        </div>
                        <p className={styles.authFooter}>
                            Copyright {new Date().getFullYear()}{' '}
                            {process.env.NEXT_PUBLIC_APP_NAME}. All Rights
                            Reserved.
                        </p>
                    </div>
                </div>
                <div
                    className={styles.authRight}
                    style={{
                        backgroundImage: `url("/auth-image.jpg")`,
                    }}
                ></div>
            </div>
        </main>
    )
}

export default Auth
