import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    item: null,
    user: null,
    fbos: null,
}

export const sharedSlice = createSlice({
    name: 'shared',
    initialState,
    reducers: {
        setItem: (state, { payload }) => {
            state.item = payload
        },
        setUser: (state, { payload }) => {
            state.user = payload
        },
        setFbos: (state, { payload }) => {
            state.fbos = payload
        },
    },
})

export const { setItem, setUser, setFbos } = sharedSlice.actions

export default sharedSlice.reducer
