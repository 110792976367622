import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const Modal = ({
    children,
    ModalBody,
    props,
    isModalOpen,
    isModalClose,
    onSubmit,
    title,
    size,
    modification,
    cancelLabel,
    scrollHeight,
    noScroll = false,
}) => {
    return (
        <>
            <Transition appear show={isModalOpen} as={Fragment}>
                <Dialog
                    className="relative z-30 w-[640px] mobile:z-[1050] mobile:w-full"
                    onClose={() => {
                        isModalOpen = false
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-common-black bg-opacity-60" />
                    </Transition.Child>
                    <div className="main-modal fixed inset-0 overflow-y-auto">
                        <div
                            className={`mx-auto flex min-h-full items-center justify-center p-4 mobile:inline-block mobile:min-h-screen mobile:p-0 ${
                                size == 'sm'
                                    ? 'w-[382px] mobile:w-full'
                                    : 'w-[680px] mobile:w-full'
                            } ${modification}`}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full rounded-lg bg-common-white mobile:h-screen mobile:rounded-none">
                                    <div>
                                        {/* Title */}
                                        <Dialog.Title className="relative mb-6 p-6 pb-0 font-acumin-bold text-2xl leading-6 text-gray-900">
                                            {title}
                                            <span
                                                className="absolute right-3 top-[14px] cursor-pointer p-3 text-base text-gray-400"
                                                onClick={isModalClose}
                                            >
                                                <i className=" icon-close"></i>
                                            </span>
                                        </Dialog.Title>
                                        {/* Body */}
                                        <div
                                            className={`scrollable mb-6 p-6 pt-1 pb-0 mobile:mb-0 ${
                                                noScroll
                                                    ? 'overflow-y-visible'
                                                    : ''
                                            }  ${
                                                scrollHeight
                                                    ? scrollHeight
                                                    : 'max-h-[580px] lg:max-h-[350px] mobile:max-h-[calc(100vh-90px)]'
                                            }`}
                                        >
                                            <div>
                                                {ModalBody && (
                                                    <ModalBody {...props} />
                                                )}
                                                {children}
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default Modal
