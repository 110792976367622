import React, { useRef, useState } from 'react'

const CopyClipboard = (props) => {
    const [btnVariant, setBtnVariant] = useState('bg-primary-500')
    const [copyText, setCopyText] = useState('Copy')
    const textAreaRef = useRef(null)

    const copyToClipboard = (event) => {
        textAreaRef.current.select()
        document.execCommand('copy')
        setCopyText('Copied!')
        event.target.focus()
        setBtnVariant('bg-success-600')
        const timer = setTimeout(() => {
            setBtnVariant('bg-primary-500')
            setCopyText('Copy')
        }, 3000)
        return () => clearTimeout(timer)
    }
    return (
        <div
            className={`relative flex h-[45px] items-center rounded-md border border-interface-300 bg-interface-50 p-2 pb-0 font-acumin-bold text-interface-500 ${props.width}`}
        >
            <div className="absolute right-2 top-[5px]">
                <button
                    onClick={copyToClipboard}
                    type="button"
                    className={`flex h-[24px] w-[80px] items-center justify-center rounded-md p-4 font-acumin-bold text-sm text-common-white shadow-md transition-all ${btnVariant}`}
                >
                    <span>{copyText}</span>
                </button>
            </div>

            <textarea
                ref={textAreaRef}
                value={props.value}
                className="-z-10 opacity-0 outline-none"
            />
            <p className="absolute top-[11px] mb-0 w-[calc(100%-110px)] overflow-hidden text-ellipsis whitespace-nowrap">
                <i className="icon-clip relative top-0.5 mr-2" />
                <span>{props.value}</span>
            </p>
        </div>
    )
}
export default CopyClipboard
