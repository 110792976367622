import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setItem, setUser, setFbos } from '@/redux/slices/sharedSlice'

import axios from '@/data/axios'

const useFetcher = () => {
    const selectedItem = useSelector((state) => state.shared.item)
    const selectedUser = useSelector((state) => state.shared.user)
    const fboList = useSelector((state) => state.shared.fbos)

    const dispatch = useDispatch()
    const fetchItem = useCallback(
        async (url) => {
            if (url) {
                const {
                    data: { data },
                } = await axios.get(url)
                dispatch(setItem(data))
            } else {
                dispatch(setItem(null))
            }
        },
        [dispatch]
    )

    const setSelectedItem = useCallback(
        (data) => {
            dispatch(setItem(data))
        },
        [dispatch]
    )

    const setFboList = useCallback(
        (data) => {
            dispatch(setFbos(data))
        },
        [dispatch]
    )

    const setSelectedUser = useCallback(
        (data) => {
            dispatch(setUser(data))
        },
        [dispatch]
    )

    return {
        fetchItem,
        selectedItem,
        setSelectedItem,
        selectedUser,
        setSelectedUser,
        fboList,
        setFboList,
    }
}

export default useFetcher
