import { useState } from 'react'
import {
    format,
    startOfWeek,
    addDays,
    isSameDay,
    lastDayOfWeek,
} from 'date-fns'

const Calendar = ({ showDetailsHandle }) => {
    const [currentMonth] = useState(new Date())
    const [selectedDate, setSelectedDate] = useState(new Date())

    const onDateClickHandle = (day, dayStr, formattedDate, i) => {
        setSelectedDate(day)
        showDetailsHandle(dayStr)
    }
    const renderCells = () => {
        const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 })
        const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 })
        const dateFormat = 'd'
        const rows = []
        let days = []
        let day = startDate
        let formattedDate = ''
        const weekFormat = 'EE'
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat)
                const cloneDay = day
                days.push(
                    <div
                        className={`col cell ${
                            isSameDay(day, selectedDate) ? 'selected' : ''
                        }`}
                        key={day}
                    >
                        <div
                            onClick={() => {
                                const dayStr = format(cloneDay, 'yyyy-MM-dd')
                                onDateClickHandle(
                                    cloneDay,
                                    dayStr,
                                    formattedDate,
                                    i
                                )
                            }}
                            className="flight-block"
                        >
                            <div className="week-set" key={i}>
                                {format(
                                    addDays(startDate, i),
                                    weekFormat
                                ).charAt(0)}
                            </div>
                            <div className="number-set">
                                {formattedDate}{' '}
                                <span className="hidden">{i}</span>
                            </div>
                        </div>
                        <span className="bg">{formattedDate}</span>
                    </div>
                )
                day = addDays(day, 1)
            }

            rows.push(
                <div className="row" key={day}>
                    {days}
                </div>
            )
            days = []
        }
        return (
            <div className="flight-day-body">
                <div className="body">{rows}</div>
            </div>
        )
    }
    return <div className="calendar">{renderCells()}</div>
}

export default Calendar
