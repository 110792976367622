import { useState, useEffect, useCallback } from 'react'

import axios from '@/data/axios'

import styles from '@/styles/Sidebar.module.css'

const Count = ({
    uri,
    setIsModalOpen,
    icon = 'icon-notification',
    type = 'other',
}) => {
    const [count, setCount] = useState(null)
    const [toggle, setToggle] = useState(false)

    /* get unread notifications count */
    const getCount = useCallback(async () => {
        try {
            const { data } = await axios.get(uri)
            if (data?.data) {
                if (data.data > 9) {
                    setCount('9+')
                } else {
                    setCount(data.data)
                }
            } else {
                setCount(0)
            }
        } catch ({ response }) {
            // sentry error handling will be added here
            if (response?.data?.message) setCount(0)
        }
    }, [uri])

    /* mark read all notifications */
    // const markRead = async () => {
    //     try {
    //         const { data } = await axios.put('/notification')
    //         if (data) {
    //             setCount(0)
    //         }
    //     } catch ({ response }) {
    //         // sentry error handling will be added here
    //         if (response?.data?.message) setCount(0)
    //     }
    // }

    const openModal = () => {
        // if (type === 'notification') {
        //     markRead()
        // }
        setCount(0)
        setIsModalOpen(!toggle)
        setToggle(!toggle)
        document.body.classList.add('overflow-hidden')
    }

    useEffect(() => {
        if (type === 'notification') {
            getCount()
            const interval = setInterval(() => {
                getCount()
            }, 300000)
            return () => clearInterval(interval)
        }
    }, [getCount, type])

    return (
        <div className={styles.notification} onClick={() => openModal()}>
            <span className={`${styles.notificationIcon} ${styles.bellIcon}`}>
                <i className={icon}></i>
                {!!count && type === 'notification' && (
                    <span className={styles.count}>{count}</span>
                )}
            </span>
        </div>
    )
}
export default Count
