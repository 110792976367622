import { useFormikContext } from 'formik'
import { useMemo } from 'react'

import kebabCase from 'lodash/kebabCase'

import styles from '@/styles/Input.module.css'

const TextBox = ({
    id,
    name,
    placeholder,
    label,
    type = 'text',
    ariaLabel,
    variant,
    maxLength,
    noSpace = false,
    disabled = false,
    readOnly = false,
    autoFocus = false,
    defaultValue = '',
    customcss,
    onClick,
    customCssContainer,
}) => {
    const { handleBlur, handleChange, getFieldMeta } = useFormikContext() || {}
    const { value, error, touched } = getFieldMeta ? getFieldMeta(name) : {}
    const id$ = useMemo(() => id || `${kebabCase(label)}`, [id, label])

    return (
        <div className={`${styles.inputModule} ${customCssContainer}`}>
            <div
                className={`${styles.formGroup} ${
                    noSpace ? styles.noSpace : ''
                }`}
            >
                {label && (
                    <label
                        htmlFor={id$}
                        className="mb-2 block font-acumin-bold text-sm text-common-black"
                    >
                        {label}
                    </label>
                )}

                <input
                    id={id$}
                    name={name}
                    type={type}
                    disabled={disabled}
                    readOnly={readOnly}
                    maxLength={maxLength}
                    autoFocus={autoFocus}
                    className={`${styles.formControl} ${styles[variant]} ${
                        error && touched ? styles.hasError : ''
                    } ${customcss}`}
                    aria-label={ariaLabel ? ariaLabel : label}
                    placeholder={placeholder}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onClick={onClick}
                    autoComplete="off"
                    value={value || defaultValue}
                />
            </div>
        </div>
    )
}

export default TextBox
