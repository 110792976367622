import Link from 'next/link'
import Image from 'next/image'

import dayjs from '@/data/dayjs'

import styles from '@/styles/Notification.module.css'

const User = ({ notificationDetail, remove, read }) => {
    const data = notificationDetail?.data ? notificationDetail.data : {}
    const message = `New client <b>${data?.client?.firstName} ${data?.client?.lastName}</b> has been added by <b>${data?.sender?.firstName} ${data?.sender?.lastName}</b>.`
    return (
        <li
            key="item.id"
            className={`${styles.item} ${
                notificationDetail?.isRead ? '' : ' bg-body-bg'
            }`}
            onClick={() => read(notificationDetail?.id)}
        >
            <div className={styles.notificationList}>
                <span className={styles.image}>
                    <Image
                        src={`/icons/${notificationDetail?.module}.svg`}
                        alt={process.env.NEXT_PUBLIC_APP_NAME}
                        layout="fixed"
                        width={24}
                        height={24}
                    />
                </span>
                <span
                    className={`${
                        notificationDetail?.isRead ? '' : 'font-acumin-bold'
                    } ${styles.description}`}
                >
                    <span>
                        <p
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                                __html: message,
                            }}
                        ></p>
                    </span>
                    <span className={styles.time}>
                        {dayjs(notificationDetail?.createdAt).fromNow()}
                    </span>
                    <div className={styles.btnClose}>
                        <span className="flex items-center justify-center p-1">
                            <i
                                onClick={() => remove(notificationDetail?.id)}
                                className="icon-close text-xs text-common-black"
                            ></i>
                        </span>
                    </div>
                </span>
                {!notificationDetail?.isRead && (
                    <div className="absolute right-3 top-[9px]">
                        <div className="h-[6px] w-[6px] rounded-full bg-primary-500"></div>
                    </div>
                )}
            </div>
            <Link href={`/clients/${data?.client?.id}`}>
                <a className="absolute h-full w-full"></a>
            </Link>
        </li>
    )
}

export default User
