import React from 'react'
import Link from 'next/link'

import styles from '@/styles/FlightDetails.module.css'

import dayjs from '@/data/dayjs'

const FlightCalendarCard = ({ data, modifications, variant }) => {
    let originIsInBetween = false
    const originDaylightStartDate = data?.originDaylightStartDate
    const originDaylightEndDate = data?.originDaylightEndDate
    if (originDaylightStartDate && originDaylightEndDate) {
        originIsInBetween = dayjs(
            `${data.takeOffDate} ${data.takeOffTime}`
        ).isBetween(
            dayjs(originDaylightStartDate),
            dayjs(originDaylightEndDate)
        )
    }

    const originTimezone =
        originIsInBetween && data?.originUTCDaylightOffset
            ? data?.originUTCDaylightOffset
            : data?.originUTCOffset || 0

    const destinationDaylightStartDate = data?.destinationDaylightStartDate
    const destinationDaylightEndDate = data?.destinationDaylightEndDate
    let destinationIsInBetween = false
    if (destinationDaylightStartDate && destinationDaylightEndDate) {
        destinationIsInBetween = dayjs(
            `${data.landingDate} ${data.landingTime}`
        ).isBetween(
            dayjs(destinationDaylightStartDate),
            dayjs(destinationDaylightEndDate)
        )
    }

    const destinationTimezone =
        destinationIsInBetween && data?.destinationUTCDaylightOffset
            ? data?.destinationUTCDaylightOffset
            : data?.destinationUTCOffset || 0

    const destinationDateTime =
        data.landingDate && data.landingTime
            ? dayjs(`${data.landingDate} ${data.landingTime}`)
                  ?.utcOffset(
                      isNaN(originTimezone)
                          ? originTimezone
                          : parseInt(originTimezone),
                      true
                  )
                  ?.utc()
                  ?.utcOffset(
                      isNaN(destinationTimezone)
                          ? destinationTimezone
                          : parseInt(destinationTimezone)
                  )
            : ''

    const destinationTime =
        destinationDateTime && !data?.takeOffTimeTBD
            ? destinationDateTime?.format('h:mm A')
            : data?.takeOffTimeTBD
            ? 'TBD'
            : '-'

    return (
        <>
            <div>
                <div
                    className={`${styles.flightComponent} ${styles[modifications]} ${styles[variant]}`}
                >
                    <Link href={`/workspace/${data.id}/flight`}>
                        <a className="absolute h-full w-full"></a>
                    </Link>
                    <div className={styles.departureCol}>
                        <span className={styles.colRows}>
                            <span className={styles.originFBOName}>
                                {data?.originCode}
                            </span>
                            <span className={`${styles.originCity}`}>
                                <span>{data?.originCityState}</span>,{' '}
                                <span>{data?.originName}</span>
                                <span className="absolute top-0 left-0 hidden h-full w-full "></span>
                            </span>
                        </span>
                    </div>
                    <div className="flex w-full cursor-pointer flex-col justify-between text-center">
                        <span className="text-center text-common-black">
                            ID {data?.id}
                        </span>
                    </div>
                    {/* Destination */}
                    <div className={styles.destinationCol}>
                        <span
                            className={`${styles.colRows} ${styles.colRowsLeft}`}
                        >
                            <span className={styles.destinationFBOName}>
                                {data?.destinationCode}
                            </span>
                            <span className={styles.destinationCity}>
                                <span>{data?.destinationCityState}</span>,{' '}
                                {data?.destinationName}
                            </span>
                        </span>
                    </div>
                </div>
                <div
                    className={`${styles.flightComponent} ${styles[modifications]} ${styles[variant]}`}
                >
                    <div className={styles.separater}></div>
                    <span className={`w-full ${styles.colRows}`}>
                        <span className={` ${styles.time}`}>
                            <span>
                                {data?.takeOffTime && !data?.takeOffTimeTBD
                                    ? dayjs(
                                          data.takeOffDate +
                                              ' ' +
                                              data.takeOffTime,
                                          'YYYY-MM-DD HH:mm:ss'
                                      )
                                          .format('h:mm A')
                                          .toString()
                                    : 'TBD'}
                            </span>
                        </span>
                    </span>
                    <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-center text-common-black">
                        {`${data?.clientFirstName} ${data?.clientLastName}`}
                    </span>
                    <span
                        className={`w-full text-right ${styles.colRows} ${styles.colRowsLeft}`}
                    >
                        <span className={styles.time}>
                            <span>
                                <div>
                                    <div>{destinationTime}</div>
                                </div>
                            </span>
                        </span>
                    </span>
                </div>
            </div>
        </>
    )
}

export default FlightCalendarCard
