const SeparatorWithText = (props) => {
    return (
        <div className={`separator-with-text ${props.additional}`}>
            {props.label && (
                <span
                    className={
                        props.bgColor
                            ? props.bgColor
                            : `bg-common-white font-acumin-bold text-xs text-gray-400 ${props.modification}`
                    }
                >
                    {props.label}
                </span>
            )}
        </div>
    )
}

export default SeparatorWithText
