import React, { useState, useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import Calendar from './Calendar'
import Details from './Details'
import Loader from '@/components/base/Loader'
import NoRecordFound from '@/components/base/NoRecordFound'

import axios from '@/data/axios'
import dayjs from '@/data/dayjs'

export default function App({ activityBlock, onClose }) {
    const router = useRouter()
    const [events, setEvents] = useState(null)
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'))
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(async (dateFilter) => {
        try {
            setLoading(true)
            const { data } = await axios.get('flight', {
                params: {
                    paginate: true,
                    limit: 5,
                    status: 'confirmed',
                    startDate: dateFilter,
                    endDate: dateFilter,
                    attributes:
                        'id,takeOffTime,takeOffDate,originName,originCode,originCity,originState,originCountry,destinationCode,destinationName,destinationCity,destinationState,destinationCountry,bookingId,landingTime,landingDate,originUTCOffset,originUTCDaylightOffset,destinationUTCOffset,destinationUTCDaylightOffset,destinationDaylightStartDate,destinationDaylightEndDate,originDaylightStartDate,originDaylightEndDate,takeOffTimeTBD',
                },
            })
            const calendarEvents = []
            data.data.map((item) => {
                calendarEvents.push({
                    id: item?.bookingId,
                    clientFirstName: item?.bookingFlight?.clientFirstName,
                    clientLastName: item?.bookingFlight?.clientLastName,
                    takeOffTime:
                        item?.takeOffTime && !item?.takeOffTimeTBD
                            ? item?.takeOffTime
                            : 'TBD',
                    takeOffDate: item?.takeOffDate,
                    originUTCOffset: item?.originUTCOffset,
                    originUTCDaylightOffset: item?.originUTCDaylightOffset,
                    landingTime:
                        item?.landingTime && !item?.takeOffTimeTBD
                            ? item?.landingTime
                            : 'TBD',
                    landingDate: item?.landingDate,
                    destinationUTCOffset: item?.destinationUTCOffset,
                    destinationUTCDaylightOffset:
                        item?.destinationUTCDaylightOffset,
                    destinationDaylightStartDate:
                        item?.destinationDaylightStartDate,
                    destinationDaylightEndDate:
                        item?.destinationDaylightEndDate,
                    aircraftName: item?.bookingFlight?.aircraftName,
                    originCode: item?.originCode,
                    originName: item?.originName,
                    originCountry: item?.originCountry,
                    originCityState: `${item?.originCity}, ${item?.originState}`,
                    destinationCode: item?.destinationCode,
                    destinationName: item?.destinationName,
                    destinationCountry: item?.destinationCountry,
                    destinationCityState: `${item?.destinationCity}, ${item?.destinationState}`,
                    takeOffTimeTBD: item?.takeOffTimeTBD,
                })
            })
            setEvents(calendarEvents)
        } catch ({ response }) {
            if (response?.data?.error) setEvents([])
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        fetchData(date)
    }, [date, fetchData])

    return (
        <div className="flight-calender-open">
            <div className="flight-calendar-container px-0">
                <h4
                    className="mb-6 cursor-pointer px-4 text-2xl hover:underline"
                    onClick={() => {
                        router.push('/workspace/flight-calendar')
                        onClose()
                    }}
                >
                    Flight Calendar
                </h4>
                <span
                    className="absolute right-4 top-6 cursor-pointer text-interface-400"
                    onClick={onClose}
                >
                    <i className="icon-close"></i>
                </span>
                <div className="mb-4 border-b border-interface-200 px-4">
                    <Calendar showDetailsHandle={(dayStr) => setDate(dayStr)} />
                </div>
                {!!events?.length && !loading && (
                    <div>
                        <Details data={events} close={onClose} />
                    </div>
                )}
                {!events?.length && loading && (
                    <div className="mt-8 -mb-2">
                        <Loader />
                    </div>
                )}
                {!events?.length && !loading && (
                    <div
                        className={`flex h-[255px] items-center justify-center`}
                    >
                        <NoRecordFound
                            imgWidth="w-[130px]"
                            description="There are no flights scheduled"
                        />
                    </div>
                )}
            </div>
            <div
                className={`${'backDrop'} ${
                    activityBlock ? 'activityBackDrop' : ''
                } `}
            ></div>
        </div>
    )
}
