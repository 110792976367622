import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import calendar from 'dayjs/plugin/calendar'
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import timezone from 'dayjs/plugin/timezone'

const utc = require('dayjs/plugin/utc')

dayjs.extend(utc)
dayjs.extend(calendar)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(timezone)

export const calendarDate = (date) => {
    return dayjs(date).calendar(undefined, {
        sameDay: '[Today,] MMM DD, YYYY [at] h:mm A',
        nextDay: '[Tomorrow,] MMM DD, YYYY [at] h:mm A',
        lastDay: '[Yesterday,] MMM DD, YYYY [at] h:mm A',
        lastWeek: 'dddd[,] MMM DD, YYYY [at] h:mm A',
        sameElse: 'dddd[,] MMM DD, YYYY [at] h:mm A',
    })
}

export default dayjs
