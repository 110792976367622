import Link from 'next/link'
import { useRouter } from 'next/router'
import OutsideClickHandler from 'react-outside-click-handler'
import React, { useState, useEffect } from 'react'
import { useSession } from '@/contexts/Session'

import Logo from '@/components/Logo'
import Count from '@/components/Count'
import BaseModal from '@/components/base/Modal'
import RecentActivities from '@/components/RecentActivities'
import FlightCalendar from '@/components/flight-calendar/Index'
import ChangePassword from '@/components/modals/ChangePassword'

import styles from '@/styles/Sidebar.module.css'

// eslint-disable-next-line max-lines-per-function
const Sidebar = (props) => {
    const router = useRouter()
    const { user, authToken, removeAuthToken, scopes } = useSession()
    const [name, setName] = useState(null)
    const [initials, setInitials] = useState(null)
    const [isRecentActivities, setIsRecentActivities] = useState()
    const [isFlightCalendar, setIsFlightCalendar] = useState()
    const [openModal, setOpenModal] = useState(false)

    const [sidePanel, setSidePanel] = useState('ipad:hidden')
    const [backDrop, setBackDrop] = useState(false)
    const showSidePanel = () => {
        setSidePanel('ipad:block ipad:z-[99] ipad:h-full')
        setBackDrop('block')
    }
    const closeSidePanel = () => {
        setSidePanel('ipad:hidden')
        setBackDrop('hidden')
    }

    useEffect(() => {
        if (user) {
            const fullName = `${user?.firstName} ${user?.lastName}`
            setName(fullName)
        }
    }, [user, setName])

    useEffect(() => {
        if (name) {
            const fullName = name.split(' ')
            let fullNameInitials = fullName[0].substring(0, 1).toUpperCase()
            if (fullName.length > 1) {
                fullNameInitials += fullName[fullName.length - 1]
                    .substring(0, 1)
                    .toUpperCase()
            }
            setInitials(fullNameInitials)
        }
    }, [name, setInitials])

    const activeNavigationClass = (link) => {
        let pathname = router.route
        if (pathname.lastIndexOf('/')) {
            pathname = pathname.slice(0, pathname.lastIndexOf('/'))
        }
        if (link && link.lastIndexOf('/')) {
            link = link.slice(0, link.lastIndexOf('/'))
        }
        return pathname === link ? styles.active : ''
    }

    const FlightCalendarClose = () => {
        setIsFlightCalendar(false)
        document.body.classList.remove('overflow-hidden')
    }

    const recentActivitiesClose = () => {
        setIsRecentActivities(false)
        document.body.classList.remove('overflow-hidden')
    }
    useEffect(() => recentActivitiesClose, [router])

    const signOut = async () => {
        const signInUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/logout`

        const fetchOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken?.accessToken}`,
            },
        }
        await fetch(signInUrl, fetchOptions)
        localStorage.setItem('eventFields', [])
        removeAuthToken('accessToken', {
            path: '/',
        })
    }

    const aircraftLabel = 'Aircraft & Airport'

    const navigations = [
        {
            label: 'Dashboard',
            icon: 'pie-chart',
            link: '/dashboard',
        },
        {
            label: 'Clients',
            icon: 'clients',
            link: '/clients',
        },
        {
            label: 'Workspace',
            icon: 'calendar',
            link: '/workspace/flight-calendar',
        },
        {
            label: 'Payments',
            icon: 'payments',
            link: '/payments',
        },
        {
            label: 'Announcements',
            icon: 'chat-bubble',
            link: '/announcements',
        },
        {
            label: 'Broker Accounts',
            icon: 'backpack',
            link: '/user-accounts',
        },
        {
            label: 'Contract & Terms',
            icon: 'file-text',
            link: '/content/contract',
        },
        {
            label: aircraftLabel,
            icon: 'aircrafts',
            link: '/aircrafts',
        },
        {
            label: 'Configurations',
            icon: 'configurations',
            link: '/configurations',
        },
        {
            label: 'Sign out',
            icon: 'signout',
            action: () => {
                signOut()
            },
        },
    ].filter(
        (navigation) =>
            navigation.label === 'Sign out' ||
            scopes.includes(navigation?.link?.slice(1)?.replace('/', '-'))
    )

    return (
        <>
            <div>
                <span
                    onClick={showSidePanel}
                    className="hidden cursor-pointer ipad:fixed ipad:left-4 ipad:top-[10px] ipad:z-[99] ipad:block"
                >
                    <i className="icon-menu1 text-4xl text-primary-500" />
                </span>

                <div className={`${styles.sidebar} ${sidePanel}`}>
                    <span
                        onClick={closeSidePanel}
                        className="right-2 hidden cursor-pointer rounded-full bg-common-white px-2 py-2 ipad:absolute ipad:top-[17px] ipad:right-3 ipad:z-[99] ipad:block ipad:h-[30px] ipad:w-[30px]"
                    >
                        <i className="icon-close text-sm text-common-black ipad:absolute ipad:right-2 ipad:top-2" />
                    </span>
                    <div className={styles.sidebarLogo}>
                        <div
                            className="h-[50px] w-[53px]"
                            onClick={closeSidePanel}
                        >
                            <Logo width={53} height={50} link="/dashboard" />
                        </div>
                        <div className={styles.icons} onClick={closeSidePanel}>
                            <Count
                                uri="flight/count"
                                setIsModalOpen={setIsFlightCalendar}
                                icon="icon-calendar"
                            />
                            <Count
                                uri="notification/count"
                                icon="icon-notification-bell"
                                type="notification"
                                setIsModalOpen={setIsRecentActivities}
                            />
                        </div>
                    </div>
                    <div
                        className={`cursor-pointer ${styles.userProfile}`}
                        onClick={() => setOpenModal(true)}
                    >
                        <div className={styles.avatar} onClick={closeSidePanel}>
                            <p className="mb-0">{initials}</p>
                        </div>
                        <div
                            className={styles.userInfo}
                            onClick={closeSidePanel}
                        >
                            <p>{name}</p>
                            <span>{user?.roleName}</span>
                        </div>
                    </div>
                    <ul
                        onClick={props.click}
                        className={`scrollable ${styles.sidebarNavigation}`}
                    >
                        {navigations.map((navigation, index) => (
                            <li
                                key={`${navigation.label}-${index}`}
                                className={activeNavigationClass(
                                    navigation.link
                                )}
                                onClick={() => {
                                    if (navigation.action) {
                                        navigation.action()
                                    }
                                }}
                            >
                                <i className={`icon-${navigation.icon}`} />
                                <span>{navigation.label}</span>
                                {navigation.link && (
                                    <Link href={navigation.link}>
                                        <a
                                            onClick={closeSidePanel}
                                            className="absolute top-0 left-0 right-0 h-full w-full text-interface-200"
                                        ></a>
                                    </Link>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className={`backdrop z-[22] ${backDrop}`}></div>
            {isRecentActivities && (
                <OutsideClickHandler onOutsideClick={recentActivitiesClose}>
                    <RecentActivities onClose={recentActivitiesClose} />
                </OutsideClickHandler>
            )}
            {isFlightCalendar && (
                <OutsideClickHandler onOutsideClick={FlightCalendarClose}>
                    <FlightCalendar
                        onClose={FlightCalendarClose}
                        activityBlock={() => setIsFlightCalendar(true)}
                    />
                </OutsideClickHandler>
            )}
            <BaseModal
                ModalBody={ChangePassword}
                isModalOpen={openModal}
                isModalClose={() => setOpenModal(false)}
                title="My Account"
                size="sm"
                props={{ setOpenModal }}
            />
        </>
    )
}

export default Sidebar
